.margen_lados_input{
    margin-left: 3%;
    margin-right: 3%;
}

.contec_tooltip{
    display: block !important;
}

.tan_min_max{
    font-size: 80%;
    padding-top: 1%;
}

.buttom_coti_consul{
    text-align: center;
}

.tam_consul{
    width: 20%;
}

.conte_styles{
    padding-top: 3%;
    margin-left: 2%;
    padding-bottom: 2%;
}

.estyles_text{
    color: #4d519a;
    font-weight: 600;
    font-size: 14px;
}