.margen_title{
    margin-left: 3%;
}

.estilo_validar_new{
    padding-top: 2.5%;
}

.top_new{
    padding-top: 2% !important;
}

.top_new2{
    padding-top: 7% !important;
}