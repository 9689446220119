.seguro_container{
    display: contents;
}

.seguro_title{
    color: #6FB7A1;
    margin-top: 30px !important;
    border-top: #76ac80;
    text-align: center;
    font-size: 55px !important;
    font-family: 'Monserrat-Bold';
    text-shadow: 1px 1px 2px #143f38;
    padding-bottom: 15px;
}

.seguro_container_row{
    display: contents;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
}

.row_estilo{
    margin-left: 15%;
    padding-top: 25px;
    margin-right: 15%;
}
.contenedor_raya3_asegu2{
    display: flex;
    justify-content: center;
}

.raya3_asegu2{
    width: 100px;
    height: 4px !important;
    background-color: #46478A !important;
    opacity: inherit !important;
    margin: 0rem 0 1rem 0 !important;
}

.contenedor_seguro{
    margin-left: 15%;
    margin-right: 15%;
}

.image-grid {
    /* width: 100%; */
}

.img_marcas_seguro{
    margin-left: 30px;
    width: 80% !important;
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;
    width: -webkit-fill-available;
    width: -moz-available;

  }

.esti_boton{
    justify-content: center;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
}

.btn-info{
    color: #364c7e !important;

}

.cotizar_estilo{
    font-family: 'Century-Gothic-Bold';
    color: #ffffff;
}


.info_prueba {
    border-color: #46478A !important;
    background-color: #46478A !important;
    /* color: #fff !important; */
}

@media screen and (max-width:1200px){
    .seguro_title{
        font-size: 55px;
    }
}
@media screen and (max-width:600px){
    .seguro_title{
        font-size: 40px !important;
    }
}