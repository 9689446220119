.centrar_estrellas_logo {
    /* padding-right: 10%; */
    margin: 10px auto;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: 55%;
    display: block;
    width: 200px;
}

.menu_letras{
    font-family: 'Monserrat-MediumItalic';
    font-style: normal !important;
    justify-content: center !important;
}

@media screen and (max-width:1200px){
    .centrar_estrellas_logo{
      
  }
}
  @media screen and (max-width:600px){
    .centrar_estrellas_logo{
        margin-left: 10% !important;
   }
}

@media screen and (max-width:1200px){
  .estilo_navbar{
    
}
}
@media screen and (max-width:1000px){
  .estilo_navbar{
    color: #ffffff !important;
  }
}

.prueba_padin{
  padding-left: 0rem !important;
}

.estilos_nabvar{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 2.5rem;
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    transition: 0.5s all ease;
  }