.estilo_precio {
    width: 100%;
}

.margen_row_person {
    margin-left: 2%;
    margin-right: 6%;
}

.estilo_contenedor_info {
    display: contents;
}

.paddin_top {
    padding-top: 3% !important;
    padding-left: 2%;
    padding-right: 2%;
}

@media only screen and (max-width: 756px) {
    .paddin_top {
    }
}

@media only screen and (max-width: 568px) {
    .paddin_top {
        max-width: 100%;

    }
}

.text_izqui {
    text-align: left !important;
}

.top_arriba_input {
    margin-top: -3% !important;

}

.top_row_new {
    margin-top: -2%;
}

.estilo_abajo {
    padding-top: 6% !important;
}

.estilo_row_cedu {
    padding-top: 2%;
    padding-bottom: 2%;
}

.bordes_cuenta {
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 2%;
}

.raya_login_new {
    width: 100%;
    height: 1px !important;
    background-color: #ececec !important;
    opacity: inherit !important;
    margin: 0rem 0 1rem 0 !important;
}

.contenedor_raya_login_new {
    display: flex;
    justify-content: center;
    margin-top: 2% !important;
}

.texto_boton_center {
    text-align: center !important;
}

.estilo_abajo_new {
    padding-top: 2%;
}

@media only screen and (max-width: 756px) {
    .texto_boton_center {
    }
}

@media only screen and (max-width: 568px) {
    .texto_boton_center {
        margin-left: 35%;

    }
}