.contenedor_logo_login{
    margin-top: -15% !important;
    text-align: center;
    padding-bottom: 10%;
}

.logo_login{
    width: 32% !important;
}

.logo_login1{
    width: 80% !important;
}

.centro_iniciar{
    text-align: center;
}

.login_nuevo{
    box-shadow: 2px 2px 5px #999;
    border-radius: 5% !important;
    background: white;
    margin-left: 35% !important;
    margin-right: 35% !important;
    margin-top: 5% !important;
   /*  margin-bottom: 3% !important; */
}


.texto_login{
    margin-top: -28%;
    padding-bottom: 8px;
    /* padding-top: 8px; */
    color: #ffffff;
    text-align: center;
    font-family: "Monserrat-Bold";
    font-size: 2.7vw !important;
}

.contenedor_raya_login{
    display: flex;
    justify-content: center;
    margin-bottom: 2% !important;
}

.raya_login{
    width: 100px;
    height: 4px !important;
    background-color: #6FB7A1 !important;
    opacity: inherit !important;
    margin: 0rem 0 1rem 0 !important;
}

.datos_login{
    display: grid;
    background: #cfd2dd;
    margin-top: 2% !important;
}

.texto_login_2{
    color: #ffffff;
    text-align: center;
    /* font-family: "Monserrat-Bold"; */
    font-size: 2vw !important;
}

.estilo_datos{
    margin-left: 15% !important;
    margin-right: 15% !important;
}

.color_morado{
    border: 0 !important;
    background: #4e5094 !important;
    border-radius: 0% !important;
}

.login_logos_entrar {
    /* margin: 10px auto; */
    margin-top: 10px;
    margin-right: 2%;
    /* margin-bottom: 10px; */
    /* margin-left: 2%; */
    display: block;
    width: 15px;
}

.estilo_row_login{
    display: contents;
}

.color_fondo_icono{
    background: #1d3253 !important;
    padding-left: 3% !important;
    padding-right: 3% !important;
}

.espacio_row_login{
    padding-bottom: 3% !important;
}

.espacio_row_login2{
    padding-bottom: 15% !important;
}

.estilo_row_login1{
    padding: 0%;
}

.parte_blog{
    width: 50%;
    /* height: 70px;
    background: rgb(206, 203, 203); */
  }
  
  .parte_blog2{
      text-align: right;
    width: 50%;
    /* height: 70px;
    background: rgb(94, 186, 230); */
  }

.tama{
    padding-left: 2%;
    font-size: 10px !important;
}

.olvido{
    font-size: 10px !important;
}

.fondo{
    padding-bottom: 10% !important;
    padding-top: 10% !important;
}

.bordes_boton{
    margin-left: 30% !important;
    margin-right: 30% !important;
}

.info_prueba_login {
    font-family: 'Monserrat-Bold';
    margin-bottom: 5%;
    margin-right: 40%;
    border-color: #ffffff !important;
    background-color: #ffffff !important;
    /* color: #fff !important; */
    margin-left: 40%;
    box-shadow: 2px 2px 5px #999;
}

.color_iniciar{
    border-color: #46478A !important;
    background-color: #46478A !important;
}

@media screen and (max-width:1200px){
    .login_nuevo{
       
    }
}
@media screen and (max-width:600px){
    .login_nuevo{
        margin-left: 15% !important;
        margin-right: 15% !important;
    }
}

@media screen and (max-width:1200px){
    .contenedor_logo_login{
       
    }
}
@media screen and (max-width:600px){
    .contenedor_logo_login{
        margin-top: 5% !important;
        padding-bottom: 0% !important;
    }
}

@media screen and (max-width:1200px){
    .info_prueba_login{
       
    }
}
@media screen and (max-width:600px){
    .info_prueba_login{
        margin-right: 30% !important;
        margin-left: 30% !important;
    }
}

@media screen and (max-width:1200px){
    .contenedor_raya_login{
       
    }
}
@media screen and (max-width:600px){
    .contenedor_raya_login{
       display: none;
    }
}

@media screen and (max-width:1200px){
    .texto_login{
       
    }
}
@media screen and (max-width:600px){
    .texto_login{
       /*  margin-bottom: -8%; */
        font-size: 20px !important;
       /*  padding-top: 8%; */
    }
}

@media screen and (max-width:1200px){
    .texto_login_2{
       
    }
}
@media screen and (max-width:600px){
    .texto_login_2{
        font-size: 13px!important;
    }
}

@media screen and (max-width:1200px){
    .datos_login{
       
    }
}
@media screen and (max-width:600px){
    .datos_login{
        margin-top: -3% !important;
    }
}

@media screen and (max-width:1200px){
    .texto_login_2{
       
    }
}
@media screen and (max-width:600px){
    .texto_login_2{
        display: none !important;
    }
}

.feedback_styles{
    color: red;
    font-size: 13px;
}

.espacio_feedback{
    padding-bottom: 3%;
    margin-top: -10%;
    text-align: center;
}

.cotizar_estilo_login{
    color: #4d519a !important;
}

.form-control{
    color: #ffffff !important;
}