.footer{
    /* background: 100%; */
    /* width: 100%; */
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.box_footer{
    border-radius: 25px;
    width: 25%;
    height: 200px;
    margin: 20px;
    box-sizing: border-box;
    font-size: 50px;
}

.box2_footer{
    border-radius: 25px;
    width: 25%;
    height: 200px;
    margin: 20px;
    box-sizing: border-box;
    font-size: 50px;
}

@media screen and (max-width:1200px){
    .box2_footer{
        width: 40%;
    }
}
@media screen and (max-width:600px){
    .box2_footer{
        width: 90%;
    }
}

@media screen and (max-width:1200px){
    .box_footer{
        width: 40%;
    }
}
@media screen and (max-width:600px){
    .box_footer{
        width: 90%;
    }
}

.centrar_estrellas_footer{
    margin: 10px auto;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: 2%;
    display: block;
    width: 150px;
/*     style="margin-top:5px; margin-bottom:0px"
 */}

.prueba_conte_parrafo2{
    /* padding-bottom: 20px; */
    color: #ffffff;
    text-align: left;
    font-family: 'Century-Gothic-Regular';
    font-size: 15px !important;
}


.prueba_conte_parrafo2_medio{
    margin-top: -5px;
    margin-left: 25%;
    /* padding-bottom: 20px; */
    color: #ffffff;
    text-align: left;
    font-family: 'Century-Gothic-Regular';
    font-size: 15px !important;
}

.prueba_conte_titu_footer{
    padding-top: 20px;
    color: #ffffff;
    text-align: center;
    font-family: 'Century-Gothic-Bold';
    font-size: 20px !important;
}

.container_footer{
    padding-top: 30px;
    background: #f3f3f3;
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }
  .container__text_footer {
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-bottom: 2%;
  }
  .smar5{
    text-align: center;
    margin: 0 !important;
    font-family: 'Monserrat-Regular';
    color: #46478A;
    font-size: 20px;
  }

  .container_para_footer {
    padding-left: 10%;
    /* margin-left: 6%; */
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
    /* margin-right: 6%; */
    padding-right: 10%;
}

/* .container_contenido_ojito{
    padding-top: 21%;
} */

.container__image {
    /* margin-top: 5%; */
    /* width: 30px;
    height: 30px; */
    border-radius: 160px;
    /* border: 3px solid #0bc0ea; */
    width: 10%;
}

.container_pincay {
    /*  padding: 20px 40px 40px;
     max-width: 600px; */
     padding-left: 7%;
     padding-right: 7%;
     display: flex;
     margin-bottom: 8%;
     /* margin-top: 16%; */
   }

.container__text_pincay {
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.smar0{
    margin: 0 !important;
    font-family: 'Century-Gothic-Regular';
    color: #ffffff;
    font-size: 14px;
  }

.espacio_footer{
    padding-top: 20%;
}

@media screen and (max-width:1200px){
    .prueba_conte_parrafo2{
        text-align: left;
    }
  }
@media screen and (max-width:600px){
    .prueba_conte_parrafo2{
        text-align: center;
   }
}

@media screen and (max-width:1200px){
    .centrar_estrellas_footer{
        margin-left: 2%;
  }
}
@media screen and (max-width:600px){
    .centrar_estrellas_footer{
        margin-left: auto;
   }
}

@media screen and (max-width:1200px){
    .prueba_conte_parrafo2_medio{
        text-align: left;
  }
}
@media screen and (max-width:600px){
    .prueba_conte_parrafo2_medio{
        margin-left: 0%;
        text-align: center;
   }
}

@media screen and (max-width:1200px){
    .espacio_footer{
        padding-top: 20%;
  }
}
@media screen and (max-width:600px){
    .espacio_footer{
        padding-top: 15%;
        margin-left: 5%;
        margin-right: 5%;
        padding-left: 15%;
   }
}