.contenedor_aseguradora_color{
    background: rgb(255, 255, 255);
}

.contenedor_aseguradoras{
    /* padding-top: 40px; */
    margin-left: 15%;
    margin-right: 15%;
}

.que_dicen_comillas_asegu{
    padding-bottom: 20px;
    padding-top: 40px;
    color: #46478A;
    text-align: center;
    font-family: 'Monserrat-Bold';
    font-size: 3.5vw !important;
}

@media screen and (max-width:1200px){
    .que_dicen_comillas_asegu
    {
      
  }
  }
  @media screen and (max-width:600px){
    .que_dicen_comillas_asegu
    {
        font-size: 25px !important;
    }
}


.contenedor_raya3_asegu{
    display: flex;
    justify-content: center;
}

.raya3_asegu{
    width: 100px;
    height: 4px !important;
    background-color: #46478A !important;
    opacity: inherit !important;
    margin: 0rem 0 1rem 0 !important;
}
.image-grid_asegu {
    /* width: 50%; */
    width: 100%;
}

.image-grid_asegu img {
    margin-bottom: 3rem;
  }

.img_marcas{
    width: 75% !important;
    display: block;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-left: 30px;
  }
  .img_marca_ojo{
    width: 50% !important;
    display: block;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-left: 30px;
  }

.primera{
    padding-top: 8%;
}
.segunda{
    padding-top: 9%;
}
.tercera{
    padding-top: 10%;
}

.quinta{
    padding-top: 4%;
}
.sexta{
    padding-left: 8%;
}
.sextima{
    padding-top: 4%;
}

.octava{
    padding-top: 2%;
}

.doceava{
    padding-top: 3%;
}

.quinciava{
    padding-top: 1%;
}

.doceava{
    padding-top: 3%;
}