.estilo_switch{
    margin-left: 5%;
    padding-bottom: 2%;
}

.estilo_validar{
    padding-top: 2.8%;
}

.tma_edefrf{
    width: 50% !important;
    background: rebeccapurple;
}

.color_2_input{
    color: black !important;
}

.paddin_top_boton{
    padding-top: 4% !important;
}

.boton_tam_adr{
    width: 20% !important;
}

/* .select__option--is-selected{
    background-color: #aa0000 !important;
        color: #000000 !important;
} */

.color_text_input{
    color: black !important;
}