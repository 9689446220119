.escoger_container2{
  display: contents;
}

.contenedor_altura2{
  /* height: 350px; */
  background: #dfe0e8;
  /* margin-top: 30px; */
}

.container_video2{
  /* padding-left: 40%; */
  display: flex;
  justify-content: center;
  margin-top: 5%;
 }

 .container_facebook2_video2{
  padding-top: 95px;
  padding-right: 40%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
 }

 .smar_simple_video2{
  text-shadow: 1px 1px 2px #143f38;
  padding-bottom: 50px;
  font-size: 8vw;
  margin: 0 !important;
  font-family: 'Monserrat-Bold';
  color: #6FB7A1;
}

.smar_video2{
  font-size: 20px;
  margin: 0 !important;
  font-family: 'Monserrat-Regular';
  color: #46478A;
}

@media screen and (max-width:1200px){
  .container_facebook2_video2 {
    padding-right: 40%;
  }
}
@media screen and (max-width:600px){
  .container_facebook2_video2 {
    padding-right: 0%;
    padding-top: 10% !important;
  }
}

.container__text_escoger2 {
  /* padding-top: 2%; */
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

@media screen and (max-width:1200px){
  .container__text_escoger2 {
    padding-right: 0%;
  }
}
@media screen and (max-width:600px){
  .container__text_escoger2 {
    margin-left: 0%;
    text-align: center;
  }
}

@media screen and (max-width:1200px){
  .esti_boton2{
    margin-left: -8%;
    justify-content: left;
  }
}
@media screen and (max-width:600px){
  .esti_boton2{
    margin-left: 40% !important;
    justify-content: center !important;
  }
}

@media screen and (max-width:1200px){
  .smar_simple_video2{
    padding-bottom: 50px;
  }
}
@media screen and (max-width:600px){
  .smar_simple_video2{
    padding-bottom: 20px;
  }
}


.centrar_mujer{
  margin-left: 15%;
  width: 80%;
}

@media screen and (max-width:1200px){
  .centrar_mujer{
    
  }
}
@media screen and (max-width:600px){
  .centrar_mujer{
    margin-left: -15% !important;
    margin-bottom: -5%;
  }
}
