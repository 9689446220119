.texto_construccion{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #464794;
    text-align: center;
    font-family: "Monserrat-Bold";
    font-size: 4.5vw !important;
}

.contenedor_construccion{
    position: relative;
    display: inline-block;
}

.contenedor_construccion1{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.color_construccion{
    font-size: 18px;
    background: #6dc1aa;
    border-radius: 50px;
    box-shadow: 2px 2px 5px #999;
}

@media screen and (max-width:1200px){
    .texto_construccion{
       
    }
}
@media screen and (max-width:600px){
    .texto_construccion{
        padding-top: 10% !important;
    }
}

@media screen and (max-width:1200px){
    .contenedor_construccion1{
       
    }
}
@media screen and (max-width:600px){
    .contenedor_construccion1{
        padding-top: 10% !important;
    }
}

@media screen and (max-width:1200px){
    .color_construccion{
       
    }
}
@media screen and (max-width:600px){
    .color_construccion{
        font-size: 10px !important;
    }
}

/* @media screen and (max-width:1200px){
    .movil_estilo{
       
    }
}
@media screen and (max-width:600px){
    .movil_estilo{
        padding-top: 10% !important;
    }
} */