.contenedor_carru {
  margin-bottom: 30%;
  /* margin-left: 13%;
  margin-top: -38%;
  margin-right: 13%; */
}

.container_para_carru {
  /* padding-left: 40%; */
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.container_text_carru {
  margin-top: 5%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.smar_simple_carru {
  text-shadow: 1px 1px 2px #143f38;
  padding-bottom: 8%;
  font-size: 4.5vw;
  margin: 0 !important;
  font-family: 'Monserrat-Bold';
  color: #c3e2d9;
}

.smar_carru {
  text-align: left;
  font-size: 1.5vw;
  margin: 0 !important;
  font-family: 'Century-Gothic-Bold';
  color: #ffffff;
  padding-top: 3%;
}

.info_carru {
  border-color: #46478A !important;
  background-color: #46478A !important;
  /* color: #fff !important; */
}

.cotizar_carru {
  font-family: 'Century-Gothic-Bold';
  font-size: 1.5vw;
  color: #ffffff;
}

.centrar_carru {
  margin: 10px auto;
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  display: block;
  width: 55px;
  /*     style="margin-top:5px; margin-bottom:0px"
 */
}

@media screen and (max-width:1200px) {
  .centrar_carru {}
}

@media screen and (max-width:600px) {
  .centrar_carru {
    display: none !important;
  }
}

.container_pincay_carru {
  display: flex;
  margin-bottom: 4%;
  margin-top: 4%;
}

.container__text_pincay_carru {
  /* margin-left: 10%; */
  display: flex;
  flex-direction: column;
  align-self: center;
}

.container_facebook2_carru {
  padding-right: 50%;
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

.container__text_carru {
  /* margin-top: 5%; */
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.contenedor_seguro_carru {
  /* margin-left: 15%;
    margin-right: 15%; */
}

.image-grid_carru {
  width: 170%;
}

.img_marcas_carru {
  margin-left: 30px;
  width: 100% !important;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
}

@media screen and (max-width:1200px) {
  .contenedor_carru {
    margin-bottom: 20%;
  }
}

@media screen and (max-width:500px) {
  .contenedor_carru {
    margin-bottom: 5%;
    /* margin-bottom: -5%; */
  }
}

@media screen and (max-width:1200px) {
  .container_text_carru {
    margin-bottom: 0%;
  }
}

@media screen and (max-width:600px) {
  .container_text_carru {
    padding-left: -6% !important;
    margin-bottom: -15% !important;
  }
}



@media screen and (max-width:1200px) {
  .container_pincay_carru {
    margin-left: 0%;
  }
}

@media screen and (max-width:600px) {
  .container_pincay_carru {
    margin-top: 25% !important;
    margin-left: 0% !important;
  }
}

@media screen and (max-width:1200px) {
  .container__text_pincay_carru {}
}

@media screen and (max-width:600px) {
  .container__text_pincay_carru {
    margin-bottom: -15% !important;
    margin-left: -7% !important;
    /* margin-right: -5%;  */
    padding-bottom: -4% !important;
  }
}

@media screen and (max-width:1200px) {
  .cotizar_carru {}
}

@media screen and (max-width:600px) {
  .cotizar_carru {
    font-size: 8px !important;
  }
}

@media screen and (max-width:1200px) {
  .smar_simple_carru {
    margin-left: 0%;
    font-size: 4vw !important;
  }
}

@media screen and (max-width:600px) {
  .smar_simple_carru {
    padding-bottom: -3%;
    /* margin-right: -2%; */
    font-size: 24px;
    padding-bottom: -20%;
    margin-left: -4% !important;
    margin-bottom: 0%;
    position: absolute;
    text-shadow: 1px 1px 2px #143f38;
  }
}

@media screen and (max-width:1200px) {
  .smar_carru {}
}

@media screen and (max-width:600px) {
  .smar_carru {
    display: none !important;
  }
}



@media screen and (max-width:1200px) {
  .image-grid_carru {}
}

@media screen and (max-width:600px) {
  .image-grid_carru {
    width: 200%;
    margin-left: -22%;
    margin-bottom: -10%;
  }
}

@media screen and (max-width:1200px) {
  .img_marcas_seguro {}
}

@media screen and (max-width:600px) {
  .img_marcas_seguro {
    margin-left: -5px;
    width: 110% !important;
  }
}


/* .carousel {
  position: 0 !important;
  position: initial;
}

.carousel-inner {
  position: static !important;
}

.carousel-item{
  position: static !important;
}

.carousel-caption{
  position: static !important;
}


@media screen and (max-width:1200px){
  .container_para_carru{
  
}
}
@media screen and (max-width:600px){
  .container_para_carru{
    margin-top: -10% !important;
  }
} */

/* .contenedor figure{
  position:relative;
   height: 250px;
   cursor: pointer;
   width: 350px;
   overflow: hidden;
   border-radius: 6px;
   box-shadow: 0px 15px 25px rgba(0,0,0,0.50);
}
.contenedor figure img{
   width: 100%;
   height: 100%;
   transition: all 400ms ease-out;
   will-change: transform;
}
.contenedor figure .capa{
   position: absolute;
   top: 0;
   width: 100%;
   height: 100%;
   background: rgba(0,103,123,0.7);
   transition: all 400ms ease-out;
   opacity: 0;
   visibility: hidden;
  text-align: center;
}
.contenedor figure:hover > .capa {
   opacity: 1;
   visibility: visible;
}
.contenedor figure:hover > .capa h3{
   margin-top: 70px;
   margin-bottom: 15px;
}
.contenedor figure:hover > img{
   transform: scale(1.3);
}
.contenedor figure .capa h3{
   color: #fff;
   font-weight: 400;
   margin-bottom: 120px;
   transition: all 400ms ease-out;
    margin-top: 30px;
}
.contenedor figure .capa p{
   color: #fff;
   font-size: 15px;
   line-height: 1.5;
   width: 100%;
   max-width: 220px;
   margin: auto;
} */


.nav-submenu_1 {
  width: auto;
  position: absolute;
  z-index: 1;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  width: auto;
}

.nav-submenu li {
  width: 100%;
  white-space: nowrap;
}

.color_new_menu {
  background: #ffffff;
  margin-left: -4.5%;
}

.nav-submenu_1_new {
  text-align: left;
  width: auto;
  position: absolute;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  /* width: auto; */
  width: 220px;
  border-radius: 10px;
  margin-top: 1.3%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-left: 20%;
}

.color_a_new {
  color: #46478A !important;
  font-weight: 800;
  font-size: 15px;
}

.color_whasapp {
  color: #46478A;
}

.container_what {
  display: flex;
}

.container_what_1 {
  display: flex;
}

.color_whasapp {
  margin-top: 4%;
  margin-left: 3%;
}

@media screen and (max-width:1200px) {
  .nav-submenu_1_new {
    margin-top: 12% !important;
    margin-left: -14.5% !important;

  }
}

@media screen and (max-width:700px) {
  .nav-submenu_1_new {
    margin-left: 1% !important;
    margin-top: 3.5% !important;

  }
}