.color_noticias{
    background: #6fb7a159;
}

.noticias{
    padding-bottom: 20px;
    padding-top: 40px;
    color: #46478A;
    text-align: center;
    font-family: 'Monserrat-Bold';
    font-size: 3.5vw !important;
}

@media screen and (max-width:1200px){
    .noticias{
      
  }
  }
  @media screen and (max-width:600px){
    .noticias{
        font-size: 25px !important;
    }
}

.contenedor_noticias{
    display: flex;
    justify-content: center;
}

.raya3_noticias{
    width: 100px;
    height: 4px !important;
    background-color: #46478A !important;
    opacity: inherit !important;
    margin: 0rem 0 1rem 0 !important;
}

.contenedor_redes{
    background: rgb(253, 253, 253);
    /* margin-top: 30px; */
}
.altura{
    height: 120px;
}
.container_facebook{
    padding-left: 40%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
   }
.container_facebook2{
    padding-right: 40%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
   }
   .container__text {
    /* padding-top: 2%; */
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .smar_simple{
    padding-bottom: 5px;
    font-size: 20px;
    margin: 0 !important;
    font-family:'Monserrat-Regular';
    color: #46478A;
  }
  .smar{
    font-size: 20px;
    margin: 0 !important;
    font-family: 'Monserrat-Bold';
    color: #46478A;
  }

  .linea {
    border-right: 1px solid rgb(202, 202, 202);
}

.centrar_iconos{
    /* margin: 10px auto; */
    margin-top: 10px;
    margin-right: 2%;
    margin-bottom: 10px;
   /*  margin-left: 2%; */
    display: block;
    width: 50px;
}

@media screen and (max-width:1200px){
    .container_facebook{
        padding-left: 40%;
    }
}
@media screen and (max-width:600px){
    .container_facebook{
        padding-left: 0%;
    }
}

@media screen and (max-width:1200px){
    .container_facebook2{
        padding-right: 40%;
    }
}
@media screen and (max-width:600px){
    .container_facebook2{
        padding-right: 0%;
    }
}