.icon_margin {
    margin-left: 0.3% !important;
    font-size: 15px;
}

.witdh-100 {
    width: 100% !important;
}

.logo_tool_tip_registro {
    width: 100% !important;
}


.contenedor_foto_mostrar {}

.tam_tooltip {
    /* width: 100%;
    height: 200px; */
}

.estilo_texto_doc {
    text-align: left !important;
    color: #ffffff !important;
}

.estilo_iz{
    position: absolute !important;
}