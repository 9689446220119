.centrar{
    margin: 10px auto;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    display: block;
    width: 150px;
    padding-top: 2%;
/*     style="margin-top:5px; margin-bottom:0px"
 */}

.icono_abajo{
    background: #ffffff;
    /* margin-top:100px;  */
    margin-bottom:0px;
    padding-bottom:4%;
}

.que_dicen{
    text-shadow: 1px 1px 2px #143f38;
    padding-top: 20px;
    color: #6FB7A1;
    text-align: center;
    font-family: 'Monserrat-Bold';
    font-size: 50px !important;
}

.nosotros_txt{
    font-style: italic;
    font-size: 22px;
    color: #46478A;
    font-family: 'Century-Gothic-Regular';
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    padding-top: 20px;
}

.esti_boton_ayu{
    justify-content: center;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
}

.info_prueba_ayu {
    
    border-color: #B3EBDB !important;
    background-color: #B3EBDB !important;
    /* color: #fff !important; */
}

.cotizar_estilo_ayu{
    font-family: 'Century-Gothic-Bold';
    font-size: 15px;
    color: #46478A;
}

@media screen and (max-width:1200px){
    .que_dicen{
        font-size: 50px !important;
  }
}
@media screen and (max-width:600px){
    .que_dicen{
        font-size: 30px !important;
   }
}

@media screen and (max-width:1200px){
    .nosotros_txt{
        font-size: 22px !important;
  }
}
@media screen and (max-width:600px){
    .nosotros_txt{
        font-size: 19px !important;
   }
}

@media screen and (max-width:1200px){
    .centrar{
  }
}
@media screen and (max-width:600px){
    .centrar{
       padding-top: 8% !important;
   }
}