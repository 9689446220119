.poliza{
    /* background: 100%; */
    /* width: 100%; */
    text-align: center;
    margin-top: 50px;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-grid2{
    width: 20%;
}

.titu_poli{
    color: rgb(173, 218, 203);   
    border-top: #76ac80;
    text-align: center;
    font-size: 35px !important;
    font-family: system-ui;
    text-shadow: 1px 1px 2px #143f38;
}

.titu_poli2{
    padding-top: 15px;
    padding-bottom: 20px;
    color: rgb(255, 255, 255);   
    border-top: #76ac80;
    text-align: center;
    font-size: 35px !important;
    font-family: system-ui;
    text-shadow: 1px 1px 2px #143f38;
}

.row_estilo_poli {
    margin-left: 15%;
    padding-top: 40px;
    margin-right: 15%;
}

.centrar_estrellas_poliza {
    margin: 10px auto;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    display: block;
    width: 50px;
}

.prueba_conte_poliza{
    padding-top: 20px;
    color: #B3EBDB;
    text-align: center;
    font-family: 'Monserrat-Regular';
    font-size: 25px !important;
    padding-bottom: 10px;
    text-shadow: 1px 1px 2px #49494b;
}
.prueba_conte_parrafo_poliza{
    padding-bottom: 20px;
    color: #ffffff;
    text-align: center;
    font-family: 'Monserrat-Bold';
    font-size: 25px !important;
    text-shadow: 1px 1px 2px #49494b;
}

.box_poliza {
    border-radius: 25px;
    width: 25%;
    height: 200px;
    margin: 20px;
    box-sizing: border-box;
    font-size: 50px;
}

.container_para_row_poliza {
    padding-left: 5%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
    padding-right: 5%;
}

@media screen and (max-width:1200px){
    .box_poliza{
        width: 40%;
    }
}
@media screen and (max-width:600px){
    .box_poliza{
        width: 90%;
    }
}

@media screen and (max-width:1200px){
    .box_poliza{
        width: 40%;
    }
}
@media screen and (max-width:600px){
    .box_poliza{
        width: 90%;
    }
}
