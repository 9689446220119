
@font-face {
    font-family: "Monserrat-Bold";
    src: url('monserrat/Montserrat-Bold.ttf') format('truetype') /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
}

@font-face {
    font-family: "Monserrat-MediumItalic";
    src: url('monserrat/Montserrat-MediumItalic.ttf') format('truetype') /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
}

@font-face {
    font-family: "Monserrat-Regular";
    src: url('monserrat/Montserrat-Regular.ttf') format('truetype') /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
}

@font-face {
    font-family: "Century-Gothic-Regular";
    src: url('gothic/gothic-regular.ttf') format('truetype') /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
}

@font-face {
    font-family: "Century-Gothic-Bold";
    src: url('gothic/gothic-bold.ttf') format('truetype') /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
}