.contactanos_datos{
    margin-left: 20% !important;
    margin-right: 20% !important;
    margin-top: 2% !important;
    margin-bottom: 3% !important;
}

.infor_color_contac{
    margin-bottom: 3% !important;
    font-size: 25px;
    color: #6fb7a1;
    text-shadow: 1px 0px 1px #143f38;
}

.tamano_largo{
    width: 100% !important;
}

.boton_contac {
    width: 100% !important;
    margin-top: 4%;
    border-color: #46478A !important;
    background-color: #46478A !important;
    //* color: #fff !important;
}

.cotizar_estilo_boton{
    font-family: 'Monserrat-Regular';
    color: #ffffff;
}

.color-contactanos{
    color: rgb(49, 49, 49) !important;
}