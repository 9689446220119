.escoger_container{
    display: contents;
}


.about-container {
    display: flex;
    /* background-color: white; */
    color: black;
    height: 70vh;
    padding: 100px 0 100px 0;
  }
  
  .about-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    padding: 0 100px 0 100px;
  }
  
  .about-desc > h3 {
    color: #4ec4b0;
    font-family: inherit;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 90px;
    text-shadow: 1px 1px 4px #071614;
  }
  
  .about-desc > p {
    font-size: 18px;
    color: #9571f8;
    line-height: 1.5;
  }
  
  .about-img {
    width: 45%;
    display: flex;
    align-self: center;
    overflow: hidden;
  }
  
  @media only screen and (max-width: 960px) {
    .about-container {
      flex-direction: column;
      padding: 0;
      height: auto;
    }
  
    .about-desc {
      width: 90%;
      margin: 0 auto;
      padding: 40px 20px 50px 20px;
    }
  
    .about-desc > h3 {
      font-size: 20px;
      text-align: center;
    }
  
    .about-img {
      width: 100%;
      margin-bottom: 50px;
    }
  
    .about-img > img {
      width: 100%;
    }
  }

.seguro_title2{
    margin-bottom: -1%;
    background: #dfe0e8;
    color: #46478A;
    margin-top: 30px !important;
    border-top: #76ac80;
    text-align: center;
    font-size: 52px !important;
    font-family: 'Monserrat-Bold';
    text-shadow: 1px 1px 2px #143f38;
    padding-bottom: 30px;
    padding-top: 50px;
}

.esti_boton2 {
    margin-left: -8%;
    color: #76ac80;
    justify-content: left;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
}

.cotizar_estilo2{
    font-family: 'Century-Gothic-Bold';
    color: #ffffff;
}

.info_prueba2 {
    border-color: #6FB7A1 !important;
    background-color: #6FB7A1 !important;
    /* color: #fff !important; */
}

.contenedor_altura{
  background: #dfe0e8;
  padding-bottom: 2.2%;
  /* height: 350px; */
  /* margin-top: 30px; */
}

.container_video{
  /* padding-left: 40%; */
  display: flex;
  justify-content: center;
  margin-top: 10%;
 }

 .container_para_video {
  margin-top: 5%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.smar_simple_video{
  text-shadow: 1px 1px 2px #143f38;
  padding-bottom: 50px;
  font-size: 8vw;
  margin: 0 !important;
  font-family: 'Monserrat-Bold';
  color: #6FB7A1;
}

.smar_video{
  font-size: 20px;
  margin: 0 !important;
  font-family: 'Monserrat-Regular';
  color: #46478A;
}

.container_facebook2_video{
  padding-top: 13%;
  padding-right: 40%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
 }

@media screen and (max-width:1200px){
  .seguro_title2{
      font-size: 52px;
  }
}
@media screen and (max-width:600px){
  .seguro_title2{
    padding-left: 4px;
    padding-right: 4px;
    font-size: 30px !important;
  }
}

@media screen and (max-width:1200px){
  .container_facebook2_video{
    padding-right: 40%;
  }
}
@media screen and (max-width:600px){
  .container_facebook2_video{
    padding-right: 0%;
    padding-bottom: 10%;
  }
}

@media screen and (max-width:1200px){
  .esti_boton2{
    margin-left: -8%;
    justify-content: left;
  }
}
@media screen and (max-width:600px){
  .esti_boton2{
    margin-left: 40% !important;
    justify-content: center !important;
  }
}

@media screen and (max-width:1200px){
  .smar_simple_video {
    text-align: left;
  }
}
@media screen and (max-width:600px){
  .smar_simple_video {
    padding-bottom: 25px;
    text-align: center;
  }
}

.container__text_escoger {
  /* padding-top: 2%; */
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  align-self: center;
}


@media screen and (max-width:1200px){
  .smar_video{
      text-align: left;
  }
}
@media screen and (max-width:600px){
  .smar_video{
      text-align: center;
  }
}

.centrar_hombre{
  margin-left: 45%;
  width: 50%;
}

@media screen and (max-width:1200px){
  .centrar_hombre{
    
  }
}
@media screen and (max-width:500px){
  .centrar_hombre{
    margin-left: 30% !important;
    margin-bottom: -10%;
  }
}
