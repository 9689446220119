.centrar_infor{
    text-align: center;
    padding-top: 1% !important;
    padding-bottom: -1% !important;
}

.infor_color{
    font-size: 20px;
    color: #6fb7a1;
    text-shadow: 1px 0px 1px #143f38;
}

.centrar_infor1{
    text-align: center;
    padding-top: 3% !important;
    padding-bottom: -1% !important;
}

.checked_centrar{
    padding-top: 2%;
    padding-bottom: 3%;
    text-align: center;
}

.tabla_estilo{
    padding-top: 2% !important;
}

.tabla_estilo1{
    padding-left: 0px !important;
    padding-top: 2% !important;
    padding-right: 0px !important;
    padding-bottom: 2% !important;
}

.boton_estilo{
    padding-top: 2.6%;
}

.posicion_elegir_estilo {
    margin-left: 8%;
    margin-right: 8%;
}

.centrar_iconos_abajo {
    margin: 10px auto;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    display: block;
    width: 50px;
}

.prueba_conte_abajo {
    padding-top: 10px;
    color: #000000;
    text-align: center;
    font-family: "Monserrat-Bold";
    font-size: 15px !important;
}

.box_abajo {
    border-radius: 25px;
    width: 25%;
    height: 130px;
    background: #C9D3DA;
    margin: 20px;
    box-sizing: border-box;
    font-size: 50px;
}

.box2_abajo {
    border-radius: 25px;
    width: 25%;
    height: 130px;
    background: #B3EBDB;
    margin: 20px;
    box-sizing: border-box;
    font-size: 50px;
}

.buttom_coti_consul_1{
    text-align: center;
    padding-top: 3%;
}

.boto_conti{
    margin-left: 37%;
    margin-right: 37%;
}