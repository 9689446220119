.top_feli{
    padding-bottom: 1%;
}

.margen_button{
    margin-right: 2%;
}

.info_new{
    color: white !important;
}