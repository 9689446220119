.contenedor_registro {
    text-align: center;
}

.logo_registro{
    width: 40% !important;
}

.centro_texto{
    text-align: center;
}

.color_input{
    color: #5e5873 !important;
}

.titu_registro{
    padding-top: 1%;
}

.anchura_wizard{
    margin-left: 5%;
    margin-right: 5%;
}
