@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

.wrapper_prueba{
  position: relative;
  /* max-width: 430px; */
  width: 100%;
  /* background: #fff; */
  /* padding: 34px; */
  border-radius: 6px;
  /* box-shadow: 0 5px 10px rgba(0,0,0,0.2); */
}
.wrapper_prueba h2{
  padding-bottom: 1%;
  text-align: center;
  /* position: relative; */
  font-size: 35px;
  font-weight: 600;
  border-top: #76ac80;
  color: #6fb7a1;
  text-shadow: 1px 1px 2px #143f38;
}

.wrapper_prueba h2::before{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 28px;
  border-radius: 12px;
  background: #4070f4;
}
.wrapper_prueba form{
  margin-top: 10px;
}
.wrapper_prueba form .input-box{
  text-align: center;
  height: 52px;
  margin: 18px 0;
}
form .input-box input{
  text-align: center;
  height: 100%;
  width: 80%;
  outline: none;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 400;
  color: #333;
  border: 1.5px solid #C7BEBE;
  border-bottom-width: 2.5px;
  border-radius: 6px;
  transition: all 0.3s ease;
}
.input-box input:focus,
.input-box input:valid{
  border-color: #46478a;
}
form .policy{
  display: inline-flex;
  align-items: center;
}
form h3{
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}
.input-box.button input{
  color: #fff;
  letter-spacing: 1px;
  border: none;
  background: #46478a;
  cursor: pointer;
}
.input-box.button input:hover{
  background: #46478a;
}
form .text h3{
 color: #333;
 width: 100%;
 text-align: center;
}
form .text h3 a{
  color: #4070f4;
  text-decoration: none;
}
form .text h3 a:hover{
  text-decoration: underline;
}

.sin_placa{
    text-align: center !important;
}

.sin_placa2{
    margin-bottom: 1%;
}

.estilo_nuevo{
    margin-left: 25% !important;
    margin-right: 25% !important; 
}

.estilo_boton{
    margin-left: 25% !important;
    margin-right: 25% !important;
}

.texto_arriba{
    margin-bottom: -1%;
    text-align: center;
}

.texto_arriba_estilo{
    color: #46478a;
}

.texto_arriba_estilo_1{
    color: #38b3fa;
}

.texto_arriba_1{
    text-align: center;
    padding-bottom: 2%;
}

.texto_arriba_abajo{
    color: #fa6f39;
}

.title-framgent{
    padding-bottom: 3%;
    text-align: center;
    margin-bottom: 0px;
    font-size: 25px;
    font-weight: 600;
    color: #4d519a
}






.select-as{

.card {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #fff;
  margin: 20px 10px;
  cursor: pointer;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/* Selectable */
.card .selectable {
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 4px solid transparent;
}
.card .selectable .check {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 20px;
  height: 20px;
}
.card .selectable .check:before {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  content: "";
  border: 20px solid;
  border-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.card .selectable .check .checkmark {
  display: block;
  font: 20px sans-serif;
  line-height: 20px;
  text-align: center;
  color: transparent;
}
.card .selectable.selected {
  border-color: #4d519a;
}
.card .selectable.selected .check:before {
  border-color: #4d519a #4d519a rgba(0, 0, 255, 0) rgba(255, 0, 0, 0);
}
.card .selectable.selected .check .checkmark {
  color: #fff;
}

.content {
  padding: 10px;
  display: block !important;
  height: 250px;
}
.content .title, .content .description {
  margin: 0;
  padding: 4px;
}

.column {
  /* float: left; */
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;

}
.column > .title {
  text-align: center;
}

button.card {
  text-align: center;
  display: block;
  cursor: pointer;
 /*  width: 180px; */
  margin: 20px auto;
  text-align: center;
  padding: 16px;
  border-radius: 10px;
  border-color: #71b7a1 !important;
  background-color: #70b7a1 !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
}
button.card:focus {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

}

.img-cobertura{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-precio{
  /* text-align: center; */
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.prince-month{
  font-size: 38px;
  font-weight: 900;
}
.month{
  color: grey;
  font-size: 12px
}
.prince-year{
  font-size: 22px;
  font-weight: 900;
}

.impuestos{
  color: green;
  font-size: 14px
}

}