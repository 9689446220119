.poliza_comillas{
    /* background: 100%; */
    /* width: 100%; */
    text-align: center;
    padding-top: 10px;
    padding-bottom: 80px;
}

.centrar_comillas {
    margin: 10px auto;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    display: block;
    width: 40px;
}

.que_dicen_comillas{
    padding-bottom: 8px;
    padding-top: 8px;
    color: #ffffff;
    text-align: center;
    font-family: 'Monserrat-Bold';
    font-size: 3.5vw !important;
}

@media screen and (max-width:1200px){
    .que_dicen_comillas
    {
      
  }
  }
  @media screen and (max-width:600px){
    .que_dicen_comillas
    {
      font-size: 25px !important;
    }
}

.contenedor_raya3{
    display: flex;
    justify-content: center;
}

.raya3{
    width: 100px;
    height: 4px !important;
    background-color: #ffffff !important;
    opacity: inherit !important;
    margin: 0rem 0 1rem 0 !important;
}

.container_para_row{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
}

.box{
    border-radius: 25px;
    width: 29%;
    height: 300px;
    background: #C9D3DA;
    margin: 20px;
    box-sizing: border-box;
    font-size: 50px;
}

.box2{
    border-radius: 25px;
    width: 29%;
    height: 300px;
    background: #B3EBDB;
    margin: 20px;
    box-sizing: border-box;
    font-size: 50px;
}

@media screen and (max-width:1200px){
    .box{
        width: 40%;
    }
}
@media screen and (max-width:600px){
    .box{
        width: 90%;
    }
}

@media screen and (max-width:1200px){
    .box2{
        width: 40%;
    }
}
@media screen and (max-width:600px){
    .box2{
        width: 90%;
    }
}

.espacio{
    margin-left: 5px;
    margin-right: 5px;
}

.prueba_conte{
    padding-top: 20px;
    color: #46478A;
    text-align: center;
    font-family: 'Monserrat-Bold';
    font-size: 14px !important;
}
.prueba_conte_parrafo{
    margin-left: 2%;
    padding-bottom: 20px;
    color: #37397f;
    text-align: center;
    font-family: "Century-Gothic-Regular";
    font-size: 13px !important;
    margin-right: 2%;
}

.prueba_conte_parrafo_pru1{
    padding-top: 7%;
    margin-left: 4%;
    padding-bottom: 20px;
    color: #37397f;
    text-align: center;
    font-family: "Century-Gothic-Regular";
    font-size: 13px !important;
    margin-right: 4%;
}

.prueba_conte_parrafo_pru2{
    padding-top: 12%;
    margin-left: 4%;
    padding-bottom: 20px;
    color: #37397f;
    text-align: center;
    font-family: "Century-Gothic-Regular";
    font-size: 13px !important;
    margin-right: 4%;
}

.prueba_conte_parrafo_pru3{
    padding-top: 11%;
    margin-left: 4%;
    padding-bottom: 20px;
    color: #37397f;
    text-align: center;
    font-family: "Century-Gothic-Regular";
    font-size: 13px !important;
    margin-right: 4%;
}
.prueba_conte_parrafo_pre{
    padding-top: 9%;
    margin-left: 4%;
    padding-bottom: 20px;
    color: #37397f;
    text-align: center;
    font-family: "Century-Gothic-Regular";
    font-size: 13px !important;
    margin-right: 4%;
}

.prueba_conte_parrafo_pre2{
    padding-top: 6%;
    margin-left: 4%;
    padding-bottom: 20px;
    color: #37397f;
    text-align: center;
    font-family: "Century-Gothic-Regular";
    font-size: 13px !important;
    margin-right: 4%;
}

.centrar_estrellas{
    margin: 10px auto;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    display: block;
    width: 150px;
/*     style="margin-top:5px; margin-bottom:0px"
 */}

 .posicion_carru{
    margin-left: 8%;
    margin-top: -80px;
    margin-right: 8%;
 }


 .hola_flecla1{
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
 }

 .carousel-control-prev{
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/fleclas_iz.svg') !important;
    width: 5%;
    margin-top: 13%;
    margin-left: -6%;
 }

 .carousel-control-next{
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/flecha_de.svg') !important;
    width: 5%;
    margin-top: 13%;
    margin-right: -6%;
 }


@media screen and (max-width:1150px){
    .carousel-control-prev{
        display: none !important;
    }
}
@media screen and (max-width:600px){
    .carousel-control-prev{
        display: none !important;
    }
}

@media screen and (max-width:1150px){
    .carousel-control-next{
        display: none !important;
    }
}
@media screen and (max-width:600px){
    .carousel-control-next{
        display: none !important;
    }
}

.carousel-indicators{
    display: none !important;
}